<template>
  <report-list></report-list>
</template>

<script>
import ReportList from "@/components/reports/ReportList";

export default {
  name: "Reports",
  components: {
    ReportList
  }
}
</script>

<style scoped>

</style>