<template>
  <div>
    <overlay-loader :is-loading="loading"></overlay-loader>
    <v-list flat>
      <v-subheader>REPORTS</v-subheader>
      <v-list-item-group
          color="primary"
      >
        <v-list-item
            v-for="(file, i) in files"
            :key="i"
        >
          <v-list-item-icon>
            <v-icon>{{$t('icons.pages')}}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-text="file" @click="download(file)"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </div>
</template>

<script>
import apiClient from "@/plugins/apiClient";

export default {
  name: "ReportList",

  data() {
    return {
      loading: false,
      files: []
    }
  },

  mounted() {
    this.getList()
  },

  methods: {
    getList() {
      this.loading = true
      apiClient.get('exports/all-reports')
      .then(response => {
        this.loading = false
        this.files = response.data
      })
    },

    download(file) {
      this.loading = true;
      let exp = file.split('/')
      console.log('exp ' , exp)
      apiClient.get('exports/report-download?file=' + file)
          .then(response => {
            this.loading = false
            let blob = new Blob([response.data], { type: 'text/csv' })
            let link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.download = exp.pop()
            link.click()
            console.log('response ', response.data)
          })
    }
  }
}
</script>

<style scoped>

</style>